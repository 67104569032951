import "../images/bond-distance-lava-bracelet-red.webp";
import "../images/bond-distance-lava-bracelet-green.webp";
import "../images/bond-distance-lava-bracelet-blue.webp";
import "../images/bond-distance-lava-bracelet-pink.webp";
import "../images/bond-distance-lava-bracelet-white.webp";
import "../images/bond-distance-lava-bracelet-yellow.webp";
import "../images/indoor-hydroponic-home-garden-2.webp";
import "../images/check.svg";

const config = {
  PUBLISHABLE_KEY:
    window.CONFIG_PUBLISHABLE_KEY ||
    "pk_live_5D9U4QWyv89FK38tQyVsMcVN00U6Cf4xat",
    SKU_ID: window.CONFIG_SKU_ID || "sku_H3bmhpkHtntk79",
    DOMAIN: window.CONFIG_DOMAIN || window.location.origin,
    REDIR: window.CONFIG_REDIR || window.location.href,
};

const $ = document.querySelector.bind(document);

var lastTen = (config.SKU_ID.slice(config.SKU_ID.length - 14));
console.log(lastTen);

let initializeStripe = () => {
  return Stripe(config.PUBLISHABLE_KEY);
};

window.addEventListener("DOMContentLoaded", (event) => {
  const $checkoutButton = $(".js-checkout-button");

  const stripe = initializeStripe();

  $checkoutButton.addEventListener("click", () => {
    stripe
      .redirectToCheckout({
        items: [{ sku: config.SKU_ID, quantity: 1 }],
        successUrl:
              config.DOMAIN +
                  "/payment-success-" + lastTen + ".html?session_id={CHECKOUT_SESSION_ID}&redirect=" + config.REDIR,
          cancelUrl:
              config.DOMAIN + "/payment-canceled.html?redirect=" + config.REDIR,
          billingAddressCollection: 'required',
      })
      .then(function(result) {
        console.log(result);
        if (result.error) {
        }
      });
  });
});

//Pass URL parameters
function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      //var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, "");
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const redirect = document.getElementById("redirect");

if(redirect) {
  //redirect.href = urlParams.get('redirect');
  let redirectUrl = urlParams.get('redirect') || '/';

  redirect.setAttribute('href', redirectUrl);
}

// Initialize Firebase
var configFirebase = {
  apiKey: "AIzaSyAUYQExq860BJdhkmcJd58t27cWdFqSEGI",
  authDomain: "general-flare.firebaseapp.com",
  databaseURL: "https://general-flare.firebaseio.com",
  projectId: "general-flare",
  storageBucket: "general-flare.appspot.com",
  messagingSenderId: "965061897903",
  appId: "1:965061897903:web:340ce23d99c9c0f4ddae5a",
};
firebase.initializeApp(configFirebase);

const db = firebase.firestore();
db.settings({ timestampsInSnapshots: true });

const form = document.querySelector("#contactForm");

//User Auth
var userLoggedIn = false;
var userSignedIn = false;

firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    // User is signed in.
    var displayName = user.displayName;
    var email = user.email;
    var emailVerified = user.emailVerified;
    var photoURL = user.photoURL;
    var isAnonymous = user.isAnonymous;
    var uid = user.uid;
    var providerData = user.providerData;
    document.getElementById("contactForm").style.display = "grid";
    document.getElementById("alert").innerHTML = "You are signed in";
    document.getElementById("alert").style.display = "block";
    document.getElementById("firebaseui-auth-container").style.display = "none";
    document.getElementById("signout").style.display = "block";
    form.email.value = email;
    form.name.value = displayName;

    userSignedIn = true;

    //Signout User
    const signout = document.querySelector("#signout");

    signout.addEventListener("click", (e) => {
      e.preventDefault();
      firebase
        .auth()
        .signOut()
        .then(
          function() {
            // Sign-out successful.
            console.log("Logout successful");
            document.getElementById("firebaseui-auth-container").style.display =
              "block";

            //localStorage.clear(); This works but not recommended
            localStorage.removeItem(
              "firebase:host:project-general-flare.firebaseio.com"
            );
          },
          function(error) {
            // An error happened.
            console.log(error);
          }
        );
    });
  } else {
    // User is signed out.
    document.getElementById("contactForm").style.display = "none";
    document.getElementById("alert").innerHTML =
      "You are not signed in. Please login to submit a support request";
    document.getElementById("alert").style.display = "block";
      document.getElementById("signout").style.display = "none";
      document.getElementById("info").style.display = "none";

    console.log("user not signed in");
    userSignedIn = false;
  }
});

//function signOut() {
//    if (userLoggedIn)
//        firebase.auth().signOut().then(function () {
//            console.log("signedOut");
//        }).catch(function () {
//            console.log("Some error!!");
//        });
//};

if (form) {
  //Contact Form
  form.addEventListener("submit", (e) => {
    e.preventDefault();

    const addSupportRequest = firebase
      .functions()
      .httpsCallable("addSupportRequest");
    addSupportRequest({
      message: form.message.value,
      product: form.product.value,
    })
      .then(() => {
        document.getElementById("alert").innerHTML =
          "Your support request has been submitted. We will contact you soon";
        document.getElementById("alert").style.display = "block";
        document.getElementById("alert").style.background = "#79c879";
        document.getElementById("contactForm").style.display = "none";
        document.getElementById("signout").style.display = "block";
        form.reset();

        // Hide alert after 3 seconds
        setTimeout(function() {
          document.getElementById("alert").style.display = "none";
        }, 3000);

        //Display logout informatiom
        document.getElementById("info").innerHTML =
          "Please click sign out button when your finished.";
        document.getElementById("info").style.display = "block";
        document.getElementById("info").style.background = "orange";
      })
      .catch((error) => {
        document.getElementById("alert").innerHTML = error.message;
        document.getElementById("alert").style.display = "block";
        document.getElementById("alert").style.background = "red";
        console.log(error.message);

        // Hide alert after 3 seconds
        setTimeout(function() {
          document.getElementById("alert").style.display = "none";
        }, 3000);
      });
  });
}

//Firebase UI
var uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true;
    },
    uiShown: function() {
      // The widget is rendered.
      // Hide the loader.
      document.getElementById("loader").style.display = "none";
    },
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: "popup",
  signInSuccessUrl: "contact-us.html",
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        // Forces account selection even when one account
        // is available.
        prompt: "select_account",
      },
    },

    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
    },
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: "terms-and-conditions.html",
  // Privacy policy url/callback.
  privacyPolicyUrl: function() {
    window.location.assign("privacy-policy.html");
  },
};

// Initialize the FirebaseUI Widget using Firebase.
var ui = new firebaseui.auth.AuthUI(firebase.auth());
// The start method will wait until the DOM is loaded.
ui.start("#firebaseui-auth-container", uiConfig);
